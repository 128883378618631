import React from 'react';
import { Link } from 'react-router-dom';
import Slide_01 from '../../images/slider/SLIDE_01.jpg';

import { BsArrowRight } from 'react-icons/bs';
import bcg from '../../images/slider/slider-bg-05.png';

import './SliderHome.scss';

function SliderHome() {
	return (
		<div className='zoomSlider'>
			<div
				className='bg-image'
				style={{ backgroundImage: `url(${Slide_01})` }}
			></div>
			<div className='container'>
				<div
					className='heading-content'
					style={{ backgroundImage: `url(${bcg})` }}
				>
					<h1>
						TENNIS<span> SPORT CLUB </span>
					</h1>
					<p>
						Sed eu volutpat arcu, a tincidunt quam. Maecenas nulla quam, feugiat
						sit amet ipsum a, dapibus porta velit.
					</p>
					<Link to='/' className='btns'>
						<span>
							Read More <BsArrowRight />
						</span>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default SliderHome;
