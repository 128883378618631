import React from 'react';
import { Link } from 'react-router-dom';
import './PageHeader.scss';

const PageHeader = (props) => {
	return (
		<div
			className='page-header'
			style={{ backgroundImage: `url(${props.bcgImage})` }}
		>
			<h1>{props.title}</h1>
			<ul>
				<li>
					<Link to='/'>POČETNA</Link>
				</li>
				<li>/ /</li>
				<li>{props.page}</li>
			</ul>
		</div>
	);
};

export default PageHeader;
