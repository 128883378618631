import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = 'https://spanovanje.probniserver.tech/api/reservations';

const initialState = {
	reservations: null,
	isLoading: true,
	selectedSpan: null,
};

export const getReservations = createAsyncThunk(
	'resevations/getReservations',
	async (thunkAPI) => {
		try {
			const token = JSON.parse(sessionStorage.getItem('user')).authorisation
				.token;

			const resp = await axios.get(url, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
			});

			return resp.data;
		} catch (error) {
			return thunkAPI.rejectWithValue('something went wrong');
		}
	}
);

const reservationsSlice = createSlice({
	name: 'resevations',
	initialState,
	reducers: {
		getSelectedSpan: (state, action) => {
			state.selectedSpan = state.reservations?.find((span) => {
				return Number(span.id) === Number(action.payload);
			});
			sessionStorage.setItem(
				'selectedSpan',
				JSON.stringify(state.selectedSpan)
			);
		},
		resetSelectedSpan: (state, action) => {
			state.selectedSpan = null;
			sessionStorage.removeItem('selectedSpan');
		},
	},
	extraReducers: {
		[getReservations.pending]: (state) => {
			state.isLoading = true;
		},
		[getReservations.fulfilled]: (state, action) => {
			// console.log(action);
			state.isLoading = false;
			state.reservations = action.payload;
		},
		[getReservations.rejected]: (state, action) => {
			//console.log(action);
			state.isLoading = false;
		},
	},
});

export const { getSelectedSpan, resetSelectedSpan } = reservationsSlice.actions;
export default reservationsSlice.reducer;
