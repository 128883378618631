import React from 'react';
import ContactForm from '../contact-form/ContactForm';
import Faq from '../faq/Faq';
import './ContactHome.scss';

const ContactHome = () => {
	return (
		<section className='contact'>
			<div className='container'>
			<div className='title'>
					<p>Stay in touch</p>
					<h2>Send message to us</h2>
					<span className='decor'>Contact</span>
				</div>
				<div className='wrapper'>
					<Faq />
					<ContactForm />
				</div>
			</div>
		</section>
	);
};

export default ContactHome;
