import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import './ContactForm.scss';

const ContactForm = () => {
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState(false);
	return (
		<div className='contact-form'>
			<Formik
				initialValues={{
					name: '',
					email: '',
					msg: '',
				}}
				onSubmit={(values, { resetForm }) => {
					axios
						.post('https://spanovanje.probniserver.tech/api/contact', values)
						.then((res) => {
							setMessage(res.data.message);
							setSuccess(true);
							setTimeout(() => {
								setMessage('');
								setSuccess(false);
							}, 2000);
						})
						.catch((err) => {
							setMessage(err.message);
							setSuccess(false);
							setTimeout(() => {
								setMessage('');
								setSuccess(false);
							}, 2000);
						});
					resetForm();
				}}
				validate={(values) => {
					const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
					const errors = {};
					if (!values.name) {
						errors.name = 'Name Required';
					}
					if (!values.email || !emailRegex.test(values.email)) {
						errors.email = 'Valid Email Required';
					}
					if (!values.msg) {
						errors.msg = 'Message Required';
					}
					return errors;
				}}
			>
				{() => (
					<Form>
						<div className='input-group'>
							<Field name='name' type='text' placeholder=' ' />
							<label htmlFor='name'>Name: </label>
						</div>
						<div className='error-message'>
							<ErrorMessage name='name' />
						</div>
						<div className='input-group'>
							<Field name='email' type='email' placeholder=' ' />
							<label htmlFor='email'>Email: </label>
						</div>
						<div className='error-message'>
							<ErrorMessage name='email' />
						</div>
						<div className='input-group txtarea'>
							<Field name='msg' component='textarea' placeholder='&nbsp;' />
							<label htmlFor='message'>Message: </label>
						</div>
						<div className='error-message'>
							<ErrorMessage name='message' />
						</div>

						<button className='btns' type='submit'>
							<span>Send</span>
						</button>
						<div className={`msg${success ? ' success' : ' error'}`}>
							{message}
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default ContactForm;
