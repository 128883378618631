import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/header/PageHeader';
import BcgImage from '../images/inner_header_01.jpg';
import './Register.scss';
import axios from 'axios';

const Register = () => {
	const navigate = useNavigate();
	const [message, setMessage] = useState('');
	return (
		<div className='register'>
			<PageHeader bcgImage={BcgImage} title='Register' page='Register' />
			<div className='container'>
				<h2>Sign Up</h2>
				<div className='register-form'>
					<Formik
						initialValues={{
							first_name: '',
							last_name: '',
							username: '',
							email: '',
							telephone: '',
							password: '',
							retypePassword: '',
							terms: false,
						}}
						onSubmit={async (values, { resetForm }) => {
							try {
								const res = await axios.post(
									'https://spanovanje.probniserver.tech/api/register',
									{
										first_name: values.first_name,
										last_name: values.last_name,
										email: values.email,
										telephone: values.telephone,
										password: values.password,
									},
									{
										headers: {
											'Content-Type': 'application/json;charset=UTF-8',
											'Access-Control-Allow-Origin': '*',
										},
									}
								);
								const data = await res.data;
								resetForm();
								navigate('/login');
								return data;
							} catch (err) {
								setMessage(err.message);
							}
						}}
						validate={(values) => {
							const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
							const telRegex = /^(\(?\+?[0-9]*\)?)?[0-9_\-()]*$/;
							const errors = {};
							if (!values.first_name) {
								errors.first_name = 'First Name Required';
							}
							if (!values.last_name) {
								errors.last_name = 'Last Name Required';
							}
							if (!values.email || !emailRegex.test(values.email)) {
								errors.email = 'Valid Email Required';
							}

							if (!values.telephone || !telRegex.test(values.telephone)) {
								errors.telephone = 'Valid Phone Required';
							}
							if (!values.password || values.password.length < 8) {
								errors.password =
									'Password Required And Must Have Min. 8 Characters.';
							}
							if (!values.retypePassword) {
								errors.retypePassword = 'Password Required.';
							}
							if (values.retypePassword !== values.password) {
								errors.retypePassword = "Password isn't match retype password";
							}
							if (values.terms === false) {
								errors.terms =
									'Must check that you agree with terms and conditions';
							}
							return errors;
						}}
					>
						{({ values }) => (
							<Form>
								<div className='input-container'>
									<div className='input-group'>
										<div className='wrapper'>
											<Field
												id='firstName'
												name='first_name'
												type='text'
												placeholder=' '
											/>
											<label htmlFor='firstName'>First Name</label>
										</div>
										<div className='error-message'>
											<ErrorMessage name='first_name' />
										</div>
									</div>
									<div className='input-group'>
										<div className='wrapper'>
											<Field
												id='lastName'
												name='last_name'
												type='text'
												placeholder=' '
											/>
											<label htmlFor='lastName'>Last Name</label>
										</div>
										<div className='error-message'>
											<ErrorMessage name='last_name' />
										</div>
									</div>

									<div className='input-group'>
										<div className='wrapper'>
											<Field
												id='email'
												name='email'
												type='email'
												placeholder=' '
											/>
											<label htmlFor='email'>Email</label>
										</div>
										<div className='error-message'>
											<ErrorMessage name='email' />
										</div>
									</div>
									<div className='input-group'>
										<div className='wrapper'>
											<Field
												id='phone'
												name='telephone'
												type='tel'
												placeholder=' '
											/>
											<label htmlFor='phone'>Phone</label>
										</div>
										<div className='error-message'>
											<ErrorMessage name='telephone' />
										</div>
									</div>
									<div className='input-group'>
										<div className='wrapper'>
											<Field
												id='password'
												name='password'
												type='password'
												placeholder=' '
											/>
											<label htmlFor='password'>Password</label>
										</div>
										<div className='error-message'>
											<ErrorMessage name='password' />
										</div>
									</div>
									<div className='input-group'>
										<div className='wrapper'>
											<Field
												id='retypePassword'
												name='retypePassword'
												type='password'
												placeholder=' '
											/>
											<label htmlFor='retypePassword'>Retype Password</label>
										</div>
										<div className='error-message'>
											<ErrorMessage name='retypePassword' />
										</div>
									</div>
									<div className='input-checkbox'>
										<div className='wrapper'>
											<Field
												id='terms'
												className='checkbox'
												type='checkbox'
												name='terms'
												checked={values.terms}
											/>
											<label htmlFor='terms'>
												Agree to terms and conditions
											</label>
										</div>
										<div className='error-message'>
											<ErrorMessage name='terms' />
										</div>
									</div>
								</div>

								<button className='btns' type='submit'>
									<span>Submit</span>
								</button>
							</Form>
						)}
					</Formik>
					<div style={{ color: 'red' }}>{message}</div>
				</div>
			</div>
			<div id='print'>
				<code></code>
			</div>
		</div>
	);
};

export default Register;
