import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import SelectField from './SelectField';
import { useSelector, useDispatch } from 'react-redux';
import { getStations } from '../features/stations/stationsSlice';
import {
	getProducts,
	resetSingleProduct,
} from '../features/products/productsSlice';
import { resetSelectedSpan } from '../features/reservations/reservationsSlice';
import axios from 'axios';

import Loader from '../../images/loader.gif';
import { FiLoader } from 'react-icons/fi';

import './OrderSpan.scss';

const OrderSpan = () => {
	const dispatch = useDispatch();
	const { stations } = useSelector((state) => state.stations);
	const { singleProduct, products, isLoading } = useSelector(
		(state) => state.products
	);
	const { user } = useSelector((state) => state.login);
	const [axiosError, settAxiosError] = useState('');
	const [success, setSuccess] = useState('');
	const [userData, setUserData] = useState(null);
	const [token, setToken] = useState(null);
	const [isStationActive, setIsStationActive] = useState(false);
	const [isSelelectedSpanActive, setIsSelectedSpanAciteve] = useState(false);
	const [formSubmitLoading, setFormSubmitLoading] = useState(false);
	const selectedSpan = JSON.parse(sessionStorage.getItem('selectedSpan'));

	useEffect(() => {
		setToken(
			JSON.parse(sessionStorage.getItem('user'))?.authorisation.token ||
				user?.authorisation.token
		);
	}, [user, token]);
	useEffect(() => {
		setIsSelectedSpanAciteve(
			products?.some(
				(product) => Number(product.id) === Number(selectedSpan?.product_id)
			)
		);
	}, [products, selectedSpan]);
	useEffect(() => {
		setIsStationActive(
			stations?.some(
				(station) => Number(station.id) === Number(selectedSpan?.station.id)
			)
		);
	}, [stations, selectedSpan]);

	useEffect(() => {
		dispatch(getStations());
		dispatch(getProducts());
	}, [dispatch]);

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			padding: 10,
			color: '#ff8d48',
			borderBottom: '1px dashed #ff8d48',
		}),

		dropdownIndicator: (provided, state) => ({
			...provided,
			color: '#ff8d48',
		}),
		control: (provided, state) => ({
			...provided,
			borderColor: '#ff8d48',
		}),
		indicatorSeparator: (provided, state) => ({
			...provided,
			backgroundColor: '#ff8d48',
		}),

		multiValueLabel: (provided, state) => ({
			...provided,
			color: '#fff',
		}),
	};

	const spanOptions =
		products &&
		products.map((item) => {
			return {
				value: item.id,
				label: item.name,
				image:
					'https://spanovanje.probniserver.tech/storage/images/' + item.image,
				price: item.price,
			};
		});

	const stationsOptions =
		stations &&
		stations.map((station) => {
			return {
				value: station.id,
				label: station.address,
			};
		});

	const SpanOption = (props) => {
		const { innerProps, innerRef } = props;

		return (
			<article
				ref={innerRef}
				{...innerProps}
				style={{
					padding: '10px 20px',
					color: '#ff8d48',
					borderBottom: '1px dashed #ff8d48',
					cursor: 'pointer',
				}}
			>
				<h4 style={{ marginBottom: '12px' }}>{props.data.label}</h4>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<img src={props.data.image} alt={props.data.label} width='50' />
					<p
						style={{ fontWeight: 800, fontSize: '25px' }}
					>{`${props.data.price}€`}</p>
				</div>
			</article>
		);
	};
	useEffect(() => {
		if (token) {
			axios
				.get('https://spanovanje.probniserver.tech/api/me', {
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + token,
					},
				})
				.then((res) => {
					setUserData(res.data.user);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [token]);

	return (
		<div className='order'>
			{isLoading ? (
				<div className='loader'>
					<img src={Loader} alt='Loader' />
				</div>
			) : (
				<Formik
					initialValues={{
						selectSpan: singleProduct
							? singleProduct.name
							: selectedSpan
							? selectedSpan.product.name
							: '',
						product: singleProduct
							? singleProduct.id
							: selectedSpan
							? selectedSpan.product.id
							: '',
						weight: selectedSpan ? selectedSpan.kilogram : 15,
						nodes: selectedSpan ? selectedSpan.knots.toString() : '2',
						date: new Date().toISOString().slice(0, 10),
						station: selectedSpan ? selectedSpan.station.address : '',
						first_name: '',
						last_name: '',
						email: '',
						telephone: '',
						password: '',
						retypePassword: '',
						terms: false,
						register: false,
					}}
					onSubmit={async (values, { resetForm }) => {
						let data = null;
						setFormSubmitLoading(true);
						if (!userData) {
							data = {
								product: selectedSpan
									? values.product.toString()
									: singleProduct
									? values.product.toString()
									: values.selectSpan.value.toString(),
								weight: values.weight.toString(),
								nodes: values.nodes,
								date: values.date,
								station: values.station.value.toString(),
								first_name: values.first_name,
								last_name: values.last_name,
								email: values.email,
								telephone: values.telephone,
								password: values.password,
								register: values.register,
							};
						} else {
							data = {
								product: selectedSpan
									? values.product.toString()
									: singleProduct
									? values.product.toString()
									: values.selectSpan.value.toString(),
								weight: values.weight.toString(),
								nodes: values.nodes,
								date: values.date,
								station: selectedSpan
									? selectedSpan.station.id.toString()
									: values.station.value.toString(),
								first_name: userData?.name.split(' ')[0],
								last_name: userData?.name.split(' ')[1],
								email: userData?.email,
								telephone: userData?.phone,
								password: values.password,
								register: values.register,
								user_id: userData?.id,
							};
						}

						try {
							const res = await axios.post(
								'https://spanovanje.probniserver.tech/reserve',
								data
							);

							setSuccess(res.data.message);
							console.log(data);
							setFormSubmitLoading(false);
							setTimeout(() => {
								setSuccess('');
							}, 3000);
							resetForm();

							return res;
						} catch (err) {
							console.log(data);
							settAxiosError(err.message);
							setFormSubmitLoading(false);
							setTimeout(() => {
								settAxiosError('');
							}, 3000);
						}
					}}
					validate={(values) => {
						const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
						const telRegex = /^(\(?\+?[0-9]*\)?)?[0-9_\-()]*$/;
						const errors = {};

						if (!values.selectSpan) {
							errors.selectSpan = 'Select at least one span';
						}
						if (values.weight < 15 || values.weight > 30) {
							errors.weight =
								'The weight cannot be less than 30 kg and more than 40 kg';
						}
						if (!values.station) {
							errors.station = 'Select station';
						}
						if (!values.date) {
							errors.date = 'Select date';
						}
						if (!userData && !values.first_name) {
							errors.first_name = 'First Name Required';
						}
						if (!userData && !values.last_name) {
							errors.last_name = 'Last Name Required';
						}
						if (
							!userData &&
							(!values.email || !emailRegex.test(values.email))
						) {
							errors.email = 'Valid Email Required';
						}

						if (
							!userData &&
							(!values.telephone || !telRegex.test(values.telephone))
						) {
							errors.telephone = 'Valid Phone Required';
						}
						if (
							(values.register && !values.password) ||
							(values.register && values.password.length < 8)
						) {
							errors.password =
								'Password Required And Must Have Min. 8 Characters.';
						}
						if (values.register && !values.retypePassword) {
							errors.retypePassword = 'Password Required.';
						}
						if (values.register && values.retypePassword !== values.password) {
							errors.retypePassword = "Password isn't match retype password";
						}
						if (!userData && values.terms === false) {
							errors.terms =
								'Must check that you agree with terms and conditions';
						}

						return errors;
					}}
				>
					{({ values, setFieldValue }) => (
						<Form>
							<div className={`content${Boolean(token) ? ' two-columns' : ''}`}>
								<div className='column'>
									<h3>1. Odaberi špan</h3>
									{singleProduct || isSelelectedSpanActive ? (
										<div className='input-group'>
											<div className='wrapper'>
												<Field
													name='selectSpan'
													type='text'
													value={
														selectedSpan
															? selectedSpan.product.name
															: values.selectSpan
													}
													disabled={true}
												/>
											</div>
										</div>
									) : (
										<Field
											name='selectSpan'
											component={SelectField}
											placeholder={!selectedSpan ? 'Select Span' : ''}
											options={spanOptions}
											styles={customStyles}
											components={{ Option: SpanOption }}
										/>
									)}

									<ErrorMessage name='selectSpan'>
										{(msg) => <div style={{ color: 'red' }}>{msg}</div>}
									</ErrorMessage>
								</div>
								<div className='column middle'>
									<div className='wrapper'>
										<h3>2. Kilaža</h3>
										<div className='input number'>
											<button
												type='button'
												onClick={() => {
													if (values.weight > 15) {
														setFieldValue('weight', values.weight - 1);
													}
												}}
											>
												-
											</button>
											<Field name='weight' type='number' min='15' max='30' />

											<button
												type='button'
												onClick={() => {
													if (values.weight < 30) {
														setFieldValue('weight', values.weight + 1);
													}
												}}
											>
												+
											</button>
										</div>
										<ErrorMessage name='weight'>
											{(msg) => <div style={{ color: 'red' }}>{msg}</div>}
										</ErrorMessage>
									</div>
									<div className='wrapper'>
										<h3>3. Čvorovi</h3>
										<div className='radio'>
											<label>
												<Field type='radio' name='nodes' value='2' />2
											</label>
											<label>
												<Field type='radio' name='nodes' value='4' />4
											</label>
											<div>Izabrano: {values.nodes}</div>
										</div>
									</div>
									<div className='wrapper'>
										<h3>4. Odaberi stanicu</h3>
										{isStationActive ? (
											<div className='input-group'>
												<div className='wrapper'>
													<Field
														name='station'
														type='text'
														value={values.station}
														disabled={true}
													/>
												</div>
											</div>
										) : (
											<div className='select'>
												<Field
													name='station'
													component={SelectField}
													placeholder={!selectedSpan ? 'Select Station' : ''}
													options={stationsOptions}
													styles={customStyles}
												/>
											</div>
										)}

										<ErrorMessage name='station'>
											{(msg) => <div style={{ color: 'red' }}>{msg}</div>}
										</ErrorMessage>
									</div>
									<div className='wrapper'>
										<h3>4. Datum</h3>
										<div className='input'>
											<Field name='date' type='date' />
										</div>
										<ErrorMessage name='date'>
											{(msg) => <div style={{ color: 'red' }}>{msg}</div>}
										</ErrorMessage>
									</div>
								</div>
								{!token && (
									<div className='column'>
										<h3>6. Lični podaci</h3>
										<div className='input-container'>
											<div className='input-group'>
												<div className='wrapper'>
													<Field
														id='firstName'
														name='first_name'
														type='text'
														placeholder=' '
													/>
													<label htmlFor='firstName'>First Name</label>
												</div>
												<div className='error-message'>
													<ErrorMessage name='first_name' />
												</div>
											</div>
											<div className='input-group'>
												<div className='wrapper'>
													<Field
														id='lastName'
														name='last_name'
														type='text'
														placeholder=' '
													/>
													<label htmlFor='lastName'>Last Name</label>
												</div>
												<div className='error-message'>
													<ErrorMessage name='last_name' />
												</div>
											</div>

											<div className='input-group'>
												<div className='wrapper'>
													<Field
														id='email'
														name='email'
														type='email'
														placeholder=' '
													/>
													<label htmlFor='email'>Email</label>
												</div>
												<div className='error-message'>
													<ErrorMessage name='email' />
												</div>
											</div>
											<div className='input-group'>
												<div className='wrapper'>
													<Field
														id='phone'
														name='telephone'
														type='tel'
														placeholder=' '
													/>
													<label htmlFor='phone'>Phone</label>
												</div>
												<div className='error-message'>
													<ErrorMessage name='telephone' />
												</div>
											</div>
											{values.register && (
												<div className='password'>
													<div className='input-group'>
														<div className='wrapper'>
															<Field
																id='password'
																name='password'
																type='password'
																placeholder=' '
															/>
															<label htmlFor='password'>Password</label>
														</div>
														<div className='error-message'>
															<ErrorMessage name='password' />
														</div>
													</div>
													<div className='input-group'>
														<div className='wrapper'>
															<Field
																id='retypePassword'
																name='retypePassword'
																type='password'
																placeholder=' '
															/>
															<label htmlFor='retypePassword'>
																Retype Password
															</label>
														</div>
														<div className='error-message'>
															<ErrorMessage name='retypePassword' />
														</div>
													</div>
												</div>
											)}

											<div className='input-checkbox'>
												<div className='wrapper'>
													<div className='with-error'>
														<div className='checkbox'>
															<Field
																id='terms'
																className='checkbox'
																type='checkbox'
																name='terms'
																checked={values.terms}
															/>
															<label htmlFor='terms'>
																Agree to terms and conditions
															</label>
														</div>
														<div className='error-message'>
															<ErrorMessage name='terms' />
														</div>
													</div>
													<div className='checkbox'>
														<Field
															id='register'
															className='checkbox'
															type='checkbox'
															name='register'
															checked={values.register}
														/>
														<label htmlFor='register'>Registruj nalog</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className='btn-group'>
								<button
									type='button'
									className='btns'
									onClick={() => {
										dispatch(resetSelectedSpan());
										dispatch(resetSingleProduct());
										setIsSelectedSpanAciteve(false);
										setIsStationActive(false);
									}}
								>
									<span>Reset</span>
								</button>
								<button className='btns' type='submit'>
									<span>Send {formSubmitLoading ? <FiLoader /> : ''}</span>
								</button>
							</div>
							<div className='message'>
								{axiosError ? (
									<p
										style={{
											color: 'red',
											textAlign: 'right',
											fontWeight: 700,
										}}
									>
										{axiosError}
									</p>
								) : null}

								{success ? (
									<p
										style={{
											color: 'green',
											textAlign: 'right',
											fontWeight: 700,
										}}
									>
										{success}
									</p>
								) : null}
							</div>
						</Form>
					)}
				</Formik>
			)}
		</div>
	);
};

export default OrderSpan;
