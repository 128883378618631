import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../components/features/login/loginSlice';
import stationsReducer from '../components/features/stations/stationsSlice';
import productsReducer from '../components/features/products/productsSlice';
import reservationsReducer from '../components/features/reservations/reservationsSlice';

export const store = configureStore({
	reducer: {
		login: loginReducer,
		stations: stationsReducer,
		products: productsReducer,
		reservations: reservationsReducer,
	},
});
