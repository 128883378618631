import React from 'react';

import PageHeader from '../components/header/PageHeader';
import BcgImage from '../images/inner_header_01.jpg';

import Product from '../components/product/Product';
import Loader from '../images/loader.gif';

import './Offer.scss';
import { useSelector } from 'react-redux';

const Offer = () => {
	const { products, isLoading } = useSelector((state) => state.products);
	return (
		<div className='offer-page'>
			<PageHeader bcgImage={BcgImage} title='Offer' page='Offer' />
			<div className='container'>
				{isLoading ? (
					<div className='loader'>
						<img src={Loader} alt='Loader' />
					</div>
				) : (
					<div className='products'>
						{products.map((span) => {
							return (
								<Product
									key={span.id}
									id={span.id}
									src={`https://spanovanje.probniserver.tech/storage/images/${span.image}`}
									title={span.name}
									content={span.description}
									price={span.regular_price}
									salePrice={span.discount_price}
								/>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default Offer;
