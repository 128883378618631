import React, { useRef, useEffect, useState } from 'react';
import FirstBall from '../../images/ball-1.png';
import SecBall from '../../images/ball-2.png';
import ThirthBall from '../../images/ball-3.png';
import Women from '../../images/services-bg-cut.png';
import './AboutHome.scss';

const AboutHome = () => {
	const ref = useRef();
	const [isInViewPort, setIsInViewPort] = useState(false);
	const [scroll, setScroll] = useState(0);
	function handleScrollAnimation() {
		const el = ref.current;
		let posOfTop = el.getBoundingClientRect().top;
		let heightOfEl = el.getBoundingClientRect().height;
		let viewPortHeight = window.innerHeight;
		if (posOfTop < viewPortHeight && posOfTop + heightOfEl > 100) {
			setIsInViewPort(true);
			setScroll((viewPortHeight - posOfTop) / 2);
		} else {
			setIsInViewPort(false);
		}
	}
	useEffect(() => {
		window.addEventListener('scroll', handleScrollAnimation);
		return () => {
			window.removeEventListener('scroll', handleScrollAnimation);
		};
	});
	return (
		<section
			className={`about-home${isInViewPort ? ' in-viewport' : ''}`}
			ref={ref}
		>
			<div className='balls-decoration' style={{ bottom: scroll }}>
				<img src={FirstBall} alt='Tenis ball' />
				<img src={SecBall} alt='Tenis ball' />
				<img src={ThirthBall} alt='Tenis ball' />
			</div>
			<div className='container'>
				<div className='image-container'>
					<img src={Women} alt='Tenis player' />
				</div>
				<div className='content'>
					<h2>
						<span>Additional services</span>
						<br /> for your convenience
					</h2>
					<p>
						Curabitur eu nisi eu eros varius elementum ac non massa. Proin
						bibendum, orci ac vulputate tristique
					</p>
					<ul>
						<li>
							<div className='num'>
								<span>01.</span>
							</div>
							<div className='text-wrapper'>
								<h3>Fitness and Gym</h3>
								<p>
									Donec vel egestas sem. Sed sed tempus dolor rhoncus nec
									porttitor vel.
								</p>
							</div>
						</li>
						<li>
							<div className='num'>
								<span>02.</span>
							</div>
							<div className='text-wrapper'>
								<h3>Swimming Pool</h3>
								<p>
									Donec vel egestas sem. Sed sed tempus dolor rhoncus nec
									porttitor ve
								</p>
							</div>
						</li>
						<li>
							<div className='num'>
								<span>02.</span>
							</div>
							<div className='text-wrapper'>
								<h3>Table Tennis</h3>
								<p>
									Donec vel egestas sem. Sed sed tempus dolor rhoncus nec
									porttitor ve
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default AboutHome;
