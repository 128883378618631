import React from 'react';
import Product from '../product/Product';
import Loader from '../../images/loader.gif';
import './SpecialOffer.scss';

const SpecialOffer = ({ specialOffer, isLoading }) => {
	return (
		<section className='special-offer'>
			<div className='container'>
				<div className='header'>
					<div className='title'>
						<h2>
							Our <span>tennis club</span> offers
							<br />
							high level services
						</h2>
						<span className='decor'>SPORT CLUB</span>
					</div>
					<p>
						Aliquam erat volutpat. Vestibulum non nibh tempor ante eleifend
						accumsan risus eu malesuada tempor rhoncus et lectus.
					</p>
				</div>
				<div className='content'>
					{isLoading ? (
						<div className='loader'>
							<img src={Loader} alt='Loader' />
						</div>
					) : (
						<Product
							key={specialOffer?.id}
							id={specialOffer?.id}
							src={`https://spanovanje.probniserver.tech/storage/images/${specialOffer?.image}`}
							title={specialOffer?.name}
							content={specialOffer?.description}
							price={specialOffer?.regular_price}
							salePrice={specialOffer?.discount_price}
						/>
					)}
				</div>
			</div>
		</section>
	);
};

export default SpecialOffer;
