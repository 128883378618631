import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useDispatch } from 'react-redux';
import { getStations } from './components/features/stations/stationsSlice';
import { getProducts } from './components/features/products/productsSlice';
import Layout from './Layout';
import Home from './pages/Home';
import Offer from './pages/Offer';
import Spanning from './pages/Spanning';
import Login from './pages/Login';
import Register from './pages/Register';
import MyAccount from './pages/MyAccount';
import PageNotFound from './pages/404';

const queryClient = new QueryClient();

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getStations());
		dispatch(getProducts());
	}, [dispatch]);
	useEffect(() => {
		dispatch(getStations());
		dispatch(getProducts());
	}, []);
	return (
		<QueryClientProvider client={queryClient}>
			<div className='App'>
				<Routes>
					<Route path='/' element={<Layout />}>
						<Route index element={<Home />} />
						<Route path='offer' element={<Offer />} />
						<Route path='swinging' element={<Spanning />} />
						<Route path='login' element={<Login />} />
						<Route path='register' element={<Register />} />
						<Route path='my-account' element={<MyAccount />} />
						<Route path='*' element={<PageNotFound />} />
					</Route>
				</Routes>
			</div>
			<ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
		</QueryClientProvider>
	);
}

export default App;
