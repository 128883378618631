import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../components/header/PageHeader';
import BcgImage from '../images/inner_header_01.jpg';
import { BsArrowRight } from 'react-icons/bs';
import './404.scss';

const PageNotFound = () => {
	return (
		<div className='page-not-found'>
			<PageHeader bcgImage={BcgImage} title='404 Not Found' page='404' />
			<div className='container'>
				<div className='content'>
					<h2>Oops! Page Not Found</h2>
					<p>
						The page you are looking for was moved, removed, renamed or might
						never existed.
					</p>
					<Link to='/' className='btns'>
						<span>
							Home Page <BsArrowRight />
						</span>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default PageNotFound;
