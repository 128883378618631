import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';
import Player from '../../images/player.jpg';
import 'leaflet/dist/leaflet.css';
import './Stations.scss';
import { ImLocation } from 'react-icons/im';
import { useSelector } from 'react-redux';
import Loader from '../../images/loader.gif';

const Stations = () => {
	const { stations } = useSelector((state) => state.stations);
	const state = stations?.map((station) => {
		return {
			address: station.address,
			location: [Number(station.lat), Number(station.lon)],
		};
	});
	const marker = state?.map((item, key) => {
		return (
			<Marker
				key={key + 1}
				position={item?.location}
				icon={
					new Icon({
						iconUrl: markerIconPng,
						iconSize: [25, 41],
						iconAnchor: [12, 41],
					})
				}
			>
				<Popup>{item.address}</Popup>
			</Marker>
		);
	});
	const places = state?.map((item, key) => {
		return (
			<li key={key + 1}>
				<ImLocation /> {item?.address}
			</li>
		);
	});

	return (
		<section className='stations'>
			<div className='wrapper'>
				<div className='addresses'>
					<h2>
						<span>TENNIS</span>
						<span>CLUB CUP</span>
					</h2>
					<p>
						Mauris pulvinar laoreet mi, ut consequat mi imperdiet nec ut sed
						puru.
					</p>
					<ul>{places}</ul>
				</div>
				<div className='map'>
					{state ? (
						<MapContainer
							center={state && state[0].location}
							zoom={11}
							scrollWheelZoom={false}
						>
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
							/>
							{marker}
						</MapContainer>
					) : (
						<div className='loader'>
							<img src={Loader} alt='Loader' />
						</div>
					)}
				</div>
				<div
					className='image'
					style={{ backgroundImage: `url(${Player})` }}
				></div>
			</div>
		</section>
	);
};

export default Stations;
