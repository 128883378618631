import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from 'react-redux';
import { getSingleProduct } from '../features/products/productsSlice';

import './Product.scss';

const Product = ({ id, src, title, price, salePrice, content }) => {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	return (
		<article className='product'>
			<span className='decoration left-top'></span>
			<span className='decoration right-bottom'></span>
			<div className='image-wrapper'>
				<img src={src} alt={title} />
			</div>
			<div className='content-wrapper'>
				<h3>{title}</h3>
				<h4>
					<span className={`price${salePrice !== price ? ' muted' : ''}`}>
						{price} €
					</span>
					{salePrice !== price ? (
						<span className='sales-price'>{salePrice} €</span>
					) : null}
				</h4>
				<div className='description'>{ReactHtmlParser(content)}</div>
				<Link
					to='/swinging'
					className='btns'
					onClick={(ev) => {
						ev.preventDefault();
						dispatch(getSingleProduct(id));
						sessionStorage.removeItem('selectedSpan');
						navigate('/swinging', { replace: true });
					}}
				>
					<span>Španuj</span>
				</Link>
			</div>
		</article>
	);
};

export default Product;
