import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = 'https://spanovanje.probniserver.tech/products';

const initialState = {
	products: null,
	singleProduct: null,
	isLoading: true,
};

export const getProducts = createAsyncThunk(
	'products/getProducts',
	async (thunkAPI) => {
		try {
			const resp = await axios.get(url);
			return resp.data;
		} catch (error) {
			return thunkAPI.rejectWithValue('something went wrong');
		}
	}
);
const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		getSingleProduct: (state, action) => {
			state.singleProduct = state?.products.find(
				(product) => product.id === action.payload
			);
		},
		resetSingleProduct: (state, action) => {
			state.singleProduct = null;
		},
	},
	extraReducers: {
		[getProducts.pending]: (state) => {
			state.isLoading = true;
		},
		[getProducts.fulfilled]: (state, action) => {
			// console.log(action);
			state.isLoading = false;
			state.products = action.payload;
		},
		[getProducts.rejected]: (state, action) => {
			//console.log(action);
			state.isLoading = false;
		},
	},
});

export const { getSingleProduct, resetSingleProduct } = productsSlice.actions;

export default productsSlice.reducer;
