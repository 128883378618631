import React from 'react';
import PageHeader from '../components/header/PageHeader';
import OrderSpan from '../components/order-span/OrderSpan';
import BcgImage from '../images/inner_header_01.jpg';

import './Spanning.scss';

const Spanning = () => {
	return (
		<div className='spanning'>
			<PageHeader bcgImage={BcgImage} title='ŠPANUJ' page='ŠPANUJ' />
			<div className='container'>
				<div className='wrapper'>
					<OrderSpan />
				</div>
			</div>
		</div>
	);
};

export default Spanning;
