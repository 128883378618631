import React from 'react';
import { MdPlayArrow } from 'react-icons/md';

import './Faq.scss';

const Faq = () => {
	const faqs = [
		{
			id: 'acc-1',
			question: 'Pitanje 1',
			answer:
				'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum veniam deleniti ducimus aperiam necessitatibus vel null  delectus laborum ex, tempora accusamus repellat beatae incidunt voluptate, nostrum tempore natus asperiores consequuntur!',
		},
		{
			id: 'acc-2',
			question: 'Pitanje 2',
			answer:
				'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum veniam deleniti ducimus aperiam necessitatibus vel null  delectus laborum ex, tempora accusamus repellat beatae incidunt voluptate, nostrum tempore natus asperiores consequuntur!',
		},
		{
			id: 'acc-3',
			question: 'Pitanje 3',
			answer:
				'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum veniam deleniti ducimus aperiam necessitatibus vel null  delectus laborum ex, tempora accusamus repellat beatae incidunt voluptate, nostrum tempore natus asperiores consequuntur!',
		},
		{
			id: 'acc-4',
			question: 'Pitanje 4',
			answer:
				'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum veniam deleniti ducimus aperiam necessitatibus vel null  delectus laborum ex, tempora accusamus repellat beatae incidunt voluptate, nostrum tempore natus asperiores consequuntur!',
		},
		{
			id: 'acc-5',
			question: 'Pitanje 5',
			answer:
				'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum veniam deleniti ducimus aperiam necessitatibus vel null  delectus laborum ex, tempora accusamus repellat beatae incidunt voluptate, nostrum tempore natus asperiores consequuntur!',
		},
	];
	const accordianHandler = (ev) => {
		ev.preventDefault();
		const target = ev.target;
		const data = target.dataset.target;
		const activeEl = document.getElementById(data);
		const accordians = document.querySelectorAll('.accordian');
		const btns = document.querySelectorAll('.accordian-btn button');

		if (activeEl.classList.contains('show')) {
			activeEl.classList.remove('show');
			activeEl.style.maxHeight = null;
		} else {
			accordians.forEach((item) => {
				item.classList.remove('show');
				item.style.maxHeight = null;
			});
			activeEl.classList.add('show');
			activeEl.style.maxHeight = activeEl.scrollHeight + 'px';
		}
		btns.forEach((item) => {
			if (item !== target) {
				item.parentElement.classList.remove('active');
			}
		});
		target.parentElement.classList.toggle('active');
	};
	const faqContent = faqs.map((item) => {
		return (
			<li key={item.id}>
				<div className='accordian-btn'>
					<MdPlayArrow />
					<button data-target={item.id} onClick={accordianHandler}>
						{item.question}
					</button>
				</div>
				<div className='accordian' id={item.id}>
					<div className='accordian-body'>
						<p>{item.answer}</p>
					</div>
				</div>
			</li>
		);
	});
	return (
		<div className='faq'>
			<h3>Faq</h3>
			<ul>{faqContent}</ul>
		</div>
	);
};

export default Faq;
