import React from 'react';
import './Footer.scss';

const year = new Date().getFullYear();
function Footer() {
	return (
		<footer className='copyright-block'>
			<div className='container'>
				<p>
					<a href='https://itexpertstudio.com/'>IT Expert Studio</a> © All
					Rights Reserved - <span>{year}</span>
				</p>
			</div>
		</footer>
	);
}

export default Footer;
