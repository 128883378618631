import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/header/PageHeader';
import BcgImage from '../images/inner_header_01.jpg';
import { ImLocation } from 'react-icons/im';
import { useSelector, useDispatch } from 'react-redux';
import {
	getReservations,
	getSelectedSpan,
} from '../components/features/reservations/reservationsSlice';

import { useWindowSize } from '../hooks/Hooks';

import './MyAccount.scss';

const MyAccount = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.login);
	const { reservations } = useSelector((state) => state.reservations);
	const token =
		JSON.parse(sessionStorage.getItem('user'))?.authorisation.token ||
		user?.authorisation.token;
	const size = useWindowSize();

	const navigate = useNavigate();
	useEffect(() => {
		if (!Boolean(token)) {
			navigate('/login');
		}
		dispatch(getReservations());
	}, []);

	const tableContent = reservations?.map((reservation) => {
		return (
			<tr key={reservation.id}>
				<td>
					<span>
						<ImLocation style={{ color: '#ff8d48' }} />
					</span>
					{reservation.station.address}
				</td>
				<td>{reservation.product.name}</td>
				<td>{reservation.knots}</td>
				<td>{reservation.kilogram}</td>

				<td>
					<button
						data-id={reservation.id}
						className='table-btns'
						onClick={btnClickHandler}
					>
						Ponovi
					</button>
				</td>
			</tr>
		);
	});
	const listContent = reservations?.map((reservation) => {
		return (
			<ul className='reservation-list' key={reservation.id}>
				<li>
					<span>Adresa stanice </span>
					<span>
						<ImLocation style={{ color: '#ff8d48' }} />{' '}
						{reservation.station.address}
					</span>
				</li>
				<li>
					<span>Naziv </span>
					<span>{reservation.product.name}</span>
				</li>
				<li>
					<span>Čvorovi </span>
					<span>{reservation.knots}</span>
				</li>
				<li>
					<span>Kilaža </span>
					<span>{reservation.kilogram}</span>
				</li>
				<li>
					<span>Status </span>
					<span>
						<button
							data-id={reservation.id}
							className='table-btns'
							onClick={btnClickHandler}
						>
							Ponovi
						</button>
					</span>
				</li>
			</ul>
		);
	});
	function btnClickHandler(ev) {
		const id = ev.target.dataset.id;
		sessionStorage.removeItem('selectedSpan');
		dispatch(getSelectedSpan(id));
		setTimeout(() => {
			navigate('/swinging');
		}, 500);
	}
	return (
		<div>
			<PageHeader bcgImage={BcgImage} title='My Account' page='My Account' />
			<section className='reservations'>
				<div className='container'>
					<h2>Reservations</h2>
					{size?.width >= 1250 ? (
						<div className='table-wrapper'>
							<table className='table'>
								<thead>
									<tr>
										<th scope='col'>Adresa stanice</th>
										<th scope='col'>Naziv</th>
										<th scope='col'>Čvorovi</th>
										<th scope='col'>Kilaža</th>
										<th scope='col'>Status</th>
									</tr>
								</thead>
								<tbody>{tableContent}</tbody>
							</table>
						</div>
					) : (
						<div className='list-wrapper'>{listContent}</div>
					)}
				</div>
			</section>
		</div>
	);
};

export default MyAccount;
