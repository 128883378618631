import React from 'react';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import CallToActionBcg from '../../images/call-to-action-paralax.jpg';
import './CallToAction.scss';

const CallToAction = () => {
	return (
		<section
			className='call-to-action'
			style={{ backgroundImage: `url(${CallToActionBcg})` }}
		>
			<div className='container'>
				<div className='content'>
					<h2>
						Free Personal
						<br />
						<span>Training</span>
					</h2>
					<p>
						Cras commodo mollis dolor sit amet tristique. Phasellus consectetur
						dolor ac sapien pharetra, vitae pulvinar turpis convallis.
					</p>
					<Link to='go-someware' className='btns'>
						<span>
							Read More <BsArrowRight />
						</span>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default CallToAction;
