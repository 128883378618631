import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { logIn } from '../components/features/login/loginSlice';
import PageHeader from '../components/header/PageHeader';
import BcgImage from '../images/inner_header_01.jpg';
import './Login.scss';

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [errMessage, setErrMessage] = useState('');

	return (
		<div className='login'>
			<PageHeader bcgImage={BcgImage} title='Login' page='Login' />
			<div className='container'>
				<h2>Fill in your login information</h2>
				<div className='login-form'>
					<Formik
						initialValues={{
							email: '',
							password: '',
						}}
						onSubmit={async (values, { resetForm }) => {
							try {
								dispatch(logIn(values));
								resetForm();
								setTimeout(() => {
									navigate('/my-account');
								}, 500);
							} catch (err) {
								setErrMessage(err.message);
							}
						}}
						validate={(values) => {
							const errors = {};

							if (!values.email) {
								errors.email = 'Valid Email Required';
							}
							if (!values.password) {
								errors.password = 'Password Required';
							}

							return errors;
						}}
					>
						<Form>
							<div className='input-group'>
								<Field
									id='email'
									name='email'
									type='text'
									placeholder=' '
									autoComplete='username'
								/>
								<label htmlFor='email'>Email or Username</label>
							</div>
							<div className='error-message'>
								<ErrorMessage name='email' />
							</div>
							<div className='input-group'>
								<Field
									id='password'
									type='password'
									name='password'
									placeholder=' '
									autoComplete='current-password'
								/>
								<label htmlFor='password'>Password</label>
							</div>
							<div className='error-message'>
								<ErrorMessage name='password' />
							</div>
							<button className='btns' type='submit'>
								<span>Submit</span>
							</button>
						</Form>
					</Formik>
					<div style={{ color: 'red' }}>{errMessage}</div>
					<div className='links'>
						<div className='link'>
							<p>
								Lost your password?
								<Link to='/add-new-password'>add a new password!</Link>
							</p>
						</div>
						<div className='link'>
							<p>
								You are not registered?
								<Link to='/register'>REGISTER HERE!</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
