import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FiMenu } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';

import './NavBar.scss';
import { logOut } from '../features/login/loginSlice';

function NavBar() {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.login);
	const [menuActive, setMenuActive] = useState(false);
	const [scrollY, setScrollY] = useState(0);
	const navigate = useNavigate();
	const token = sessionStorage.getItem('user') || user?.authorisation.token;
	const scrollHandler = () => {
		setScrollY(window.scrollY);
	};
	useEffect(() => {
		window.addEventListener('scroll', scrollHandler);
		return () => {
			window.removeEventListener('scroll', scrollHandler);
		};
	});
	const openMenu = () => {
		setMenuActive(true);
	};
	const closeMenu = () => {
		setMenuActive(false);
	};
	return (
		<nav className={`navbar${scrollY > 450 ? ' white-bcg' : ''}`}>
			<div className='container'>
				<div className='logo'>
					<Link to='/'>Logo</Link>
				</div>
				<div className='mobile-btn'>
					<IconContext.Provider value={{ color: '#ff8d48', size: '2.4rem' }}>
						<button onClick={openMenu}>
							<FiMenu />
						</button>
					</IconContext.Provider>
				</div>
				<div className={`menu-bar${menuActive ? ' active' : ''}`}>
					<div className='close'>
						<div className='logo'>
							<Link to='/'>Logo</Link>
						</div>
						<div className='close-btn'>
							<IconContext.Provider value={{ color: '#fff', size: '2.4rem' }}>
								<button onClick={closeMenu}>
									<IoClose />
								</button>
							</IconContext.Provider>
						</div>
					</div>
					<ul className='menu'>
						<li>
							<NavLink to='/' onClick={closeMenu}>
								POČETNA
							</NavLink>
						</li>
						<li>
							<NavLink to='offer' onClick={closeMenu}>
								PONUDA
							</NavLink>
						</li>
						<li>
							<NavLink to='swinging' onClick={closeMenu}>
								ŠPANUJ
							</NavLink>
						</li>
						<li>
							{!Boolean(token) ? (
								<NavLink to='login' onClick={closeMenu}>
									LOGIN
								</NavLink>
							) : (
								<button
									onClick={() => {
										dispatch(logOut());
										navigate('login');
									}}
								>
									Logout
								</button>
							)}
						</li>

						<li>
							<NavLink to='my-account' onClick={closeMenu}>
								MOJ RAČUN
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
}

export default NavBar;
