import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = 'https://spanovanje.probniserver.tech/stations';
const initialState = {
	stations: null,
	isLoading: true,
};

export const getStations = createAsyncThunk(
	'stations/getStations',
	async (thunkAPI) => {
		try {
			const resp = await axios.get(url);

			return resp.data;
		} catch (error) {
			return thunkAPI.rejectWithValue('something went wrong');
		}
	}
);
const stationsSlice = createSlice({
	name: 'stations',
	initialState,
	reducers: {
		clear: (state) => {
			state.stations = null;
		},
	},
	extraReducers: {
		[getStations.pending]: (state) => {
			state.isLoading = true;
		},
		[getStations.fulfilled]: (state, action) => {
			// console.log(action);
			state.isLoading = false;
			state.stations = action.payload;
		},
		[getStations.rejected]: (state, action) => {
			//console.log(action);
			state.isLoading = false;
		},
	},
});

export const { clear } = stationsSlice.actions;
export default stationsSlice.reducer;
