import React from 'react';

import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import Center from '../../images/icons-center.jpg';
import Loader from '../../images/loader.gif';
import './Offer.scss';

const Offer = () => {
	const { products, isLoading } = useSelector((state) => state.products);
	if (isLoading) {
		return (
			<div className='loader'>
				<img src={Loader} alt='Loader' />
			</div>
		);
	}

	return (
		<section className='offer'>
			<div className='container'>
				<div className='title'>
					<p>Our offers</p>
					<h2>Full Comfort for Members</h2>
					<span className='decor'>OFFERS</span>
				</div>
				<div className='wrapper'>
					<ul className='left-list'>
						{products
							.filter((item, key) => {
								return key < 4;
							})
							.map((item) => {
								return (
									<li key={item.id}>
										<img
											src={`https://spanovanje.probniserver.tech/storage/images/${item.image}`}
											alt={item.name}
											width='100'
										/>
										<div className='list-content'>
											<h3>{item.name}</h3>
											<div className='description'>
												{ReactHtmlParser(item.short_description)}
											</div>
										</div>
									</li>
								);
							})}
					</ul>
					<div className='image'>
						<img src={Center} alt='Tenis Racket' />
					</div>
					<ul className='list-right'>
						{products
							.filter((item, key) => {
								return key >= 4;
							})
							.map((item) => {
								return (
									<li key={item.id}>
										<img
											src={`https://spanovanje.probniserver.tech/storage/images/${item.image}`}
											alt={item.name}
											width='100'
										/>
										<div className='list-content'>
											<h3>{item.name}</h3>
											<div className='description'>
												{ReactHtmlParser(item.short_description)}
											</div>
										</div>
									</li>
								);
							})}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default Offer;
