import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = 'https://spanovanje.probniserver.tech/api/login';

const initialState = {
	user: null,
	isLoading: true,
};

export const logIn = createAsyncThunk(
	'login/logIn',
	async ({ email, password }, thunkAPI) => {
		try {
			const resp = await axios.post(
				url,
				{ email, password },
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				}
			);
			sessionStorage.setItem('user', JSON.stringify(resp.data));
			return resp.data;
		} catch (error) {
			return thunkAPI.rejectWithValue('something went wrong');
		}
	}
);
const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		logOut: (state) => {
			state.user = null;
			sessionStorage.removeItem('user');
			sessionStorage.removeItem('selectedSpan');
		},
	},
	extraReducers: {
		[logIn.pending]: (state) => {
			state.isLoading = true;
		},
		[logIn.fulfilled]: (state, action) => {
			// console.log(action);
			state.isLoading = false;
			state.user = action.payload;
		},
		[logIn.rejected]: (state, action) => {
			//console.log(action);
			state.isLoading = false;
		},
	},
});

export const { logOut } = loginSlice.actions;

export default loginSlice.reducer;
