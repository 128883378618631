import React from 'react';
import AboutHome from '../components/about-home/AboutHome';
import CallToAction from '../components/call-to-action-home/CallToAction';
import ContactHome from '../components/contact-home/ContactHome';
import Offer from '../components/offer/Offer';
import SliderHome from '../components/slider-home/SliderHome';
import SpecialOffer from '../components/special-offer-home/SpecialOffer';
import Stations from '../components/stations/Stations';
import { useSelector } from 'react-redux';

function Home() {
	const { isLoading, products } = useSelector((state) => state.products);

	const specialOffer = products?.find((product) => product.featured === 1);
	return (
		<div>
			<SliderHome />
			<SpecialOffer isLoading={isLoading} specialOffer={specialOffer} />
			<CallToAction />
			<AboutHome />
			<Stations />
			<Offer />
			<ContactHome />
		</div>
	);
}

export default Home;
